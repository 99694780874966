<template>
    <el-dialog class="dialog_div" title="新建课程内容"  width="45%" 
    :append-to-body="true" :visible.sync="AddCourseContentDialogVisible" :close-on-click-modal="false">
        <div>
        <el-form ref="CourseContentFormRef" label-width="10%" 
        :rules="CourseContentFormRules" :model="CourseContentForm" v-loading="Loading">
            <el-form-item label="课程：">
                <el-cascader
                    class="addcoursecontent_item" 
                    placeholder="请选择课程"
                    v-model="CourseContentForm.SelectIDs"
                    collapse-tags
                    :options="CourseCFAndCourse"
                    :props="CascaderProps"
                    clearable>
                </el-cascader>
            </el-form-item>
            <el-form-item label="名称：" prop="Name">
                <el-input class="addcoursecontent_item" placeholder="请填写名称" autocomplete="off"
                 v-model="CourseContentForm.Name" ></el-input>
            </el-form-item>
            <el-form-item label="课程目标：" prop="Evolution">
                <el-input type="textarea" placeholder="请输入内容" autocomplete="off" maxlength="500"
                :autosize="{ minRows: 15, maxRows: 20}" 
                v-model="CourseContentForm.Evolution"
                show-word-limit></el-input>
            </el-form-item>
        </el-form>
          
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog">取消</el-button>
            <el-button type="primary"
             @click="SubmitCourseContent" :disabled="Loading">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { PostCourseContentInfo } from '@/assets/js/api/courseapi' 
import { GetCourseClassFicationAndCourse } from '@/assets/js/api/courseclassficationapi' 
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
       
    },
    computed:{
        AddCourseContentDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            CourseContentForm:{
                SelectIDs:[],
                CourseIDs:[],
                Name:'',
                Evolution:'',
                CourseClassFicationID:0
            },
            CourseCFAndCourse:[],
            CascaderProps:{
                value:'id',
                label:'name',
                children:'children',
                multiple:true,
                checkStrictly: false
            },
            CourseContentFormRules:{
                Name: [
                    { required: true, message: '请填写名称', trigger: 'blur' }
                ],
                SelectIDs: [
                    { required: true, message: '请填写选择课程', trigger: 'change' }
                ]
                
            },
            Loading:false
        }
    },
    watch:{
       AddCourseContentDialogVisible:function (newVisible) {
            if(newVisible){
                //数据初始化
                //Object.assign(this.$data, this.$options.data());
                Object.assign(this.$data.CourseContentForm, this.$options.data().CourseContentForm);
                this.CourseClassFicationAndCourse()
            }
        }
    },
    created(){
        
    },
    methods:{
        async SubmitCourseContent(){
            this.$refs.CourseContentFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.CourseContentForm.CourseIDs = []
            for(var i = 0; i < this.CourseContentForm.SelectIDs.length; i++ ){
                this.CourseContentForm.CourseIDs.push(this.CourseContentForm.SelectIDs[i][1])
            }
            this.Loading = true
            var ret = await PostCourseContentInfo(this.CourseContentForm)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('提交课程内容成功')
                this.$refs.CourseContentFormRef.resetFields();
                this.$emit("ChangeAddCourseContentInfo", ret.data)
                this.AddCourseContentDialogVisible = false
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        CloseDialog(){
            this.$refs.CourseContentFormRef.resetFields();
            this.Loading = false
            this.AddCourseContentDialogVisible = false
        },
        async CourseClassFicationAndCourse(){
            this.Loading = true
            var ret = await GetCourseClassFicationAndCourse()
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseCFAndCourse = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
            
        },
    }
}
</script>

<style scoped lang="less">
    .addcoursecontent_item{
        width: 50%;
        display: flex;
    }
    .dialog_div{
        text-align: center;
    }
</style>