<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="上传课程内容文件" :visible.sync="UploadCourseContentFileDialogVisible" :close-on-click-modal="false" width="45%">
        <div class="upload_div">
            <el-upload
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">尽量将所有文件压缩到一个压缩包中</div>
            </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="UploadCourseContentFileDialogVisible = false">关 闭</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Upload} from 'element-ui';
Vue.use(Upload)
export default {
    components:{
 
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
      
    },
    computed:{
        UploadCourseContentFileDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            
        }
    },
    watch:{
        // UploadCourseContentFileDialogVisible:function (newVisible) {
        //     if(newVisible){
                
        //     }
        // }
    },
    created(){
        
    },
    methods:{
       
        
    }
}
</script>

<style scoped lang="less">
.upload_div{
    text-align: center;
}
.dialog_div{
    text-align: center;
}
</style>