<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="4">
                    <el-select class=".plane_select" placeholder="请选择老师" 
                    v-model="TeacherID" clearable>
                        <el-option 
                        v-for="item in TeacherList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-input placeholder="请输入课程名称"  v-model="QueryInfo.Name" clearable @clear="initQueryCourseContentList"></el-input>
                </el-col>
                <el-col :span="3">
                    <el-button type="primary" @click="initQueryCourseContentList">查询</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" 
                    @click="AddCourseContentDialogVisible = true">新建课程内容</el-button> 
                </el-col>
            </el-row>
            <el-table :data="CourseContentList" @row-click="CourseContentClick" v-loading="Loading" height="74vh">
                <template slot="empty">
                    <el-empty :image-size="100" description='暂无数据'></el-empty>
                </template>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="创建者" prop="creatorName"></el-table-column>
                <el-table-column label="创建时间" prop="createTime" sortable></el-table-column>
                <el-table-column label="操作" prop="option" >
                    <template slot-scope="CourseContent"> 
                        <el-tooltip effect="dark" content="上传文件" placement="top" :enterable="false">
                            <el-button type="success" icon="el-icon-upload" 
                            @click.stop="UploadCourseContentFileDialogVisible= true" disabled></el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="查看课程文件" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-folder" 
                            disabled></el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" 
                            @click.stop="DeleteCourseContentInfo(CourseContent.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                :total="CourseContentTotal">
            </el-pagination>
        </el-card>
    <AddCourseContentDialog :visible.sync="AddCourseContentDialogVisible" 
    v-on:ChangeAddCourseContentInfo="ParentChangeAddCourseContentInfo($event)">
    </AddCourseContentDialog>

    <ModifyCourseContentDialog :visible.sync="ModifyCourseContentDialogVisible"  :coursecontentinfo="ModifyCourseContentInfo"
   v-on:ChangeModifyCourseContentInfo="ParentChangeModifyCourseContentInfo($event)">
    </ModifyCourseContentDialog>
    <UploadCourseContentFileDialog :visible.sync="UploadCourseContentFileDialogVisible">
    </UploadCourseContentFileDialog>
    </div>
</template>

<script>
import AddCourseContentDialog from './addcoursecontentdialog';
import ModifyCourseContentDialog from './modifycoursecontentdialog';
import UploadCourseContentFileDialog from './uploadcoursecontentfiledialog';
import { GetCourseContentList, GetCourseContentByID, DeleteCourseContent } from '@/assets/js/api/courseapi' 
import { GetTeachers } from '@/assets/js/api/userapi' 
import { SpreadCount } from '@/assets/js/systemvariable'
export default {
    components:{
        AddCourseContentDialog,
        ModifyCourseContentDialog,
        UploadCourseContentFileDialog
    },
    data(){
        return{
            QueryInfo:{
                CreatorID:0,
                Name:'',
                Start:0,
                Count:SpreadCount[0]
            },
            CourseContentList:[],
            CourseContentTotal:0,
            AddCourseContentDialogVisible:false,
            ModifyCourseContentDialogVisible:false,
            UploadCourseContentFileDialogVisible:false,
            ModifyCourseContentInfo:{},
            TeacherID:'',
            TeacherList:[],
            Loading:false,
            spreadCount:SpreadCount,
        }
    },
    created(){
        this.Teachers()
        this.QueryCourseContentList()
    },
    methods:{
        async CourseContentClick(row){
            var ret = await GetCourseContentByID(row.id);
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.ModifyCourseContentInfo = ret.data
                this.ModifyCourseContentDialogVisible = true
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        CourseContentFile(row){
            console.log(row)
        },
        async DeleteCourseContentInfo(id){
            const confirmResult = await this.$confirm('确认是否删除课程内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            this.Loading = true
            var ret = await DeleteCourseContent(id)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除成功')
                this.DeleteIndex("id", id)
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        DeleteIndex(name , val){
            var index = 0
            for(var i = 0; i < this.CourseContentList.length; i++){
                if(this.CourseContentList[i].name == val){
                index = i
                }
            }
            this.CourseContentList.splice(index,1)
            this.CourseContentTotal --
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QueryCourseContentList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QueryCourseContentList()
        },
        initQueryCourseContentList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = this.spreadCount[0]
            this.QueryCourseContentList()
        },
        async QueryCourseContentList(){
            this.Loading = true
            this.QueryInfo.CreatorID = this.TeacherID * 1
            var ret = await GetCourseContentList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseContentList = ret.data.coursecontents
                this.CourseContentTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        ParentChangeAddCourseContentInfo(coursecontent){
            //向数组头添加元素
            this.CourseContentList.unshift(coursecontent)
            if(this.CourseContentList.length > this.QueryInfo.Count)
            {
                //删除最后一个元素
                this.CourseContentList.pop()
            }
            this.CourseContentTotal += 1
        },
        ParentChangeModifyCourseContentInfo(coursecontent){
            for (var i = 0; i < this.CourseContentList.length; i++) {
                if (this.CourseContentList[i]["id"] == coursecontent.id) {
                    this.CourseContentList[i]["name"] = coursecontent.name
                    this.CourseContentList[i]["creatorName"] = coursecontent.creatorName
                    break
                }
            }
        },
        async Teachers(){
            var ret = await GetTeachers()
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.TeacherList = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>
